"use client";

import React, { useState } from "react";
import { Button } from "@/edges/ui/Button";
import { TextField } from "@/edges/ui/TextField";
import { Form } from "@/edges/ui/Form";
import { Heading } from "@/edges/ui/Heading";
import { useAddWorkspace } from "./hooks/useAddWorkspace";
import { useTranslations } from "next-intl";
import { z } from "zod";
type AddWorkspaceFormProps = {
  onSuccess?(): void;
  onCanceled?(): void;
};
const AddWorkspaceForm: React.FC<AddWorkspaceFormProps> = ({
  onSuccess,
  onCanceled
}) => {
  const {
    handleAddWorkspace,
    loadingCreateWorkspace
  } = useAddWorkspace({
    onSuccess,
    onCanceled
  });
  const t = useTranslations();
  const [error, setError] = useState<string | null>(null);
  const [workspaceName, setWorkspaceName] = useState("");
  const validationSchema = z.object({
    name: z.string().min(1).max(50).regex(/^[a-zA-Z0-9\s-_]+$/, {
      message: "Workspace name can only contain letters, numbers, spaces, hyphens, and underscores"
    })
  });
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      validationSchema.parse({
        name: workspaceName
      });
      handleAddWorkspace({
        name: workspaceName
      });
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.errors[0].message);
      }
    }
  };
  return <Form onSubmit={handleSubmit} data-sentry-element="Form" data-sentry-component="AddWorkspaceForm" data-sentry-source-file="index.tsx">
      <Heading data-sentry-element="Heading" data-sentry-source-file="index.tsx">{t("workspace.add")}</Heading>
      <TextField label={t("common.name")} name="name" type="text" isRequired maxLength={50} value={workspaceName} onChange={setWorkspaceName} errorMessage={error ? error : undefined} data-sentry-element="TextField" data-sentry-source-file="index.tsx" />

      <div className="flex gap-2">
        <Button type="reset" variant="secondary" onPress={onCanceled} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {t("common.cancel")}
        </Button>
        <Button type="submit" isDisabled={loadingCreateWorkspace || !workspaceName.trim()} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {t("common.save")}
        </Button>
      </div>
    </Form>;
};
export default AddWorkspaceForm;