"use client";

import React from "react";
import Image from "next/image";
import TextureLogoBrand from "./texture_logo_brand.svg";
import TextureLogoBrandWordmark from "./texture_logo_brand_wordmark.svg";
import TextureLogoDark from "./texture_logo_dark.svg";
import TextureLogoDarkWordmark from "./texture_logo_dark_wordmark.svg";
import TextureLogoWhite from "./texture_logo_white.svg";
import TextureLogoWhiteWordmark from "./texture_logo_white_wordmark.svg";
interface LogoProps {
  color: "dark" | "white" | "brand";
  withWordmark: boolean;
  width: number;
  height: number;
  className?: string;
}
const Logo: React.FC<LogoProps> = ({
  withWordmark,
  width,
  height,
  className
}) => {
  const logoMap = {
    brand: withWordmark ? TextureLogoBrandWordmark : TextureLogoBrand,
    dark: withWordmark ? TextureLogoDarkWordmark : TextureLogoDark,
    white: withWordmark ? TextureLogoWhiteWordmark : TextureLogoWhite
  };

  // const currentColorMode = useColorMode();

  // const renderColor = currentColorMode === "light" ? "dark" : "white";
  // const logoPath = logoMap[renderColor];
  const logoPath = logoMap.dark;
  return <Image src={logoPath.src} alt="Logo" width={width} height={height} className={className} data-sentry-element="Image" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx" />;
};
export { Logo };