import React from "react";
import { Separator as RACSeparator, SeparatorProps } from "react-aria-components";
const baseStyles = "bg-gray-300 dark:bg-zinc-600 forced-colors:bg-[ButtonBorder]";
const orientationStyles = {
  horizontal: "h-px w-full",
  vertical: "w-px"
};
export function Separator(props: SeparatorProps) {
  const orientation = props.orientation ?? "horizontal";
  const className = [baseStyles, orientationStyles[orientation], props.className].filter(Boolean).join(" ");
  return <RACSeparator {...props} className={className} data-sentry-element="RACSeparator" data-sentry-component="Separator" data-sentry-source-file="Separator.tsx" />;
}