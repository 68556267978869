"use client";
import { useCallback, useEffect, useMemo } from "react";

import { useSelectedOrganization } from "@/state/user/useSelectedOrganization";
import { Workspace } from "@/@codegen/supergraph";

import { useWorkspaceStore } from "./useWorkspaceStore";

export function useSelectedWorkspace() {
  const { selectedOrganization } = useSelectedOrganization();
  const { workspacesMap, setSelectedWorkspace: setWorkspaceStore } = useWorkspaceStore();

  const setSelectedWorkspace = useCallback((newWorkspace: Workspace | null) => {
    if (!selectedOrganization) {
      throw new Error("No selected organization");
    };

    setWorkspaceStore(newWorkspace, selectedOrganization);
  }, [setWorkspaceStore, selectedOrganization]);

  const selectedWorkspace = useMemo(() => {
    return workspacesMap[selectedOrganization?.id ?? ""];
  }, [workspacesMap, selectedOrganization]);

  // if no workspace is selected, select the first workspace in the organization
  useEffect(() => {
    if (selectedOrganization && !selectedWorkspace?.id) {
      const [firstWorkspace] = selectedOrganization?.workspaces || [];

      if (firstWorkspace) {
        setSelectedWorkspace(firstWorkspace);
      }
    }
  }, [workspacesMap, selectedOrganization]);

  return {
    selectedWorkspace,
    setSelectedWorkspace,
  };
}

