"use client";

import React, { useState } from "react";
import { SideNav } from "@/edges/ui/SideNav";
import { TopNav } from "@/edges/ui/TopNav";
export interface AppLayoutProps {
  links: {
    iconName: string;
    label: string;
    href: string;
  }[];
  selectedLink?: string;
  helpLink: string;
  className?: string;
  children: React.ReactNode;
  userMenu: React.ReactNode;
  workspaceSwitcher: React.ReactNode;
}
function AppLayout({
  links,
  selectedLink,
  helpLink = "https://docs.texturehq.com/docs/home/welcome",
  className,
  children,
  userMenu,
  workspaceSwitcher
}: AppLayoutProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const baseClasses = "flex h-screen overflow-hidden relative";
  return <div data-testid="app-layout" className={`${baseClasses} ${className || ""}`} data-sentry-component="AppLayout" data-sentry-source-file="AppLayout.tsx">
      {menuOpen && <div className="absolute bottom-0 left-0 right-0 top-0 bg-gray-500 opacity-50 transition-opacity" onClick={toggleMenu} role="button" tabIndex={0} />}
      <SideNav toggleMenu={toggleMenu} menuOpen={menuOpen} links={links} selectedLink={selectedLink} collapsed={collapsed} toggleCollapse={toggleCollapse} workspaceSwitcher={workspaceSwitcher} data-sentry-element="SideNav" data-sentry-source-file="AppLayout.tsx" />
      <div className="absolute bottom-0 left-0 right-0 top-0 flex grow flex-col overflow-hidden md:static md:w-full">
        <TopNav toggleMenu={toggleMenu} userMenu={userMenu} helpLink={helpLink} data-sentry-element="TopNav" data-sentry-source-file="AppLayout.tsx" />
        <div className="flex h-full flex-col items-center overflow-y-auto bg-appBg pt-16 dark:bg-appBgDark">
          {children}
        </div>
      </div>
    </div>;
}
export { AppLayout };