"use client";

import React from "react";
import { Menu, MenuItem, Separator } from "react-aria-components";
import { Icon } from "@/edges/ui/Icon";
import { Button } from "@/edges/ui/Button";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import useOrganizationList from "./hooks/useOrganizationList";
import { useNavigationService } from "@/services/navigationService";
import { Organization, Membership } from "@/@codegen/supergraph";
import { useSelectedOrganization } from "@/state/user/useSelectedOrganization";
import { useLoggedUser } from "@/state/user/useLoggedUser";
import { ROUTES } from "@/routes";
import { useTranslations } from "next-intl";
import OrganizationMenuItem from "./OrganizationMenuItem";
type OrganizationListProps = {
  onSwitchOrganization?: (organization?: Organization) => void;
};
const OrganizationList = ({
  onSwitchOrganization
}: OrganizationListProps) => {
  const t = useTranslations();
  const {
    selectedOrganization
  } = useSelectedOrganization();
  const {
    navigateTo
  } = useNavigationService();
  const gotoCreateOrganization = () => {
    onSwitchOrganization?.();
    navigateTo(ROUTES.private.onboardingOrganization);
  };
  const {
    loggedUser
  } = useLoggedUser();
  const memberships = React.useMemo(() => loggedUser?.memberships?.filter(membership => membership?.organization?.personal === false) as Membership[] || [], [loggedUser]);
  const {
    isSelectedOrganization
  } = useLoggedUserFunctions();
  const {
    onSwitchOrganizationSession
  } = useOrganizationList();
  const handleSelectOrganization = React.useCallback((organization: Organization) => () => {
    if (isSelectedOrganization(organization)) {
      return;
    }
    onSwitchOrganizationSession(organization as Organization).then(() => {
      onSwitchOrganization?.(organization);
      navigateTo(ROUTES.private.home);
    });
  }, [onSwitchOrganizationSession, isSelectedOrganization]);
  if (!memberships?.length || !selectedOrganization) {
    return null;
  }
  return <div data-sentry-component="OrganizationList" data-sentry-source-file="OrganizationList.tsx">
      <div className="text-foreground text-center text-base font-medium">
        {t("organization.switch")}
      </div>
      <Separator className="my-1 border-t border-borders dark:border-bordersDark" data-sentry-element="Separator" data-sentry-source-file="OrganizationList.tsx" />
      <div className="max-h-[300px] overflow-auto">
        <Menu data-sentry-element="Menu" data-sentry-source-file="OrganizationList.tsx">
          {memberships.map(membership => <MenuItem key={membership?.organization?.id} onAction={() => {
          handleSelectOrganization(membership?.organization as Organization)();
        }} className="flex cursor-pointer items-center justify-between px-2 py-2 hover:bg-highlight dark:hover:bg-highlightDark">
              <OrganizationMenuItem organization={membership?.organization} />
              {membership?.organization?.id === selectedOrganization.id && <Icon name="Check" size={20} className="text-primary dark:text-primaryDark" />}
            </MenuItem>)}
        </Menu>
      </div>
      <Separator className="my-1 border-t border-borders dark:border-bordersDark" data-sentry-element="Separator" data-sentry-source-file="OrganizationList.tsx" />
      <Button fullWidth variant="secondary" onPress={gotoCreateOrganization} icon="Plus" data-sentry-element="Button" data-sentry-source-file="OrganizationList.tsx">
        {t("organization.create")}
      </Button>
    </div>;
};
export default OrganizationList;