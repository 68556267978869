import { Avatar } from "@/edges/ui/Avatar";
import { useTranslations } from "next-intl";
interface OrganizationMenuItemProps {
  organization: any; // not using supergraph types here
  withTitle?: boolean;
  darkText?: boolean;
}
const OrganizationMenuItem = ({
  organization,
  withTitle,
  darkText
}: OrganizationMenuItemProps) => {
  const t = useTranslations("organization");
  return <div className="flex flex-row items-center gap-2" data-sentry-component="OrganizationMenuItem" data-sentry-source-file="OrganizationMenuItem.tsx">
      <div className="mr-2 flex items-center justify-center pl-4">
        <Avatar size="sm" firstName={organization?.name ?? ""} imageUrl={organization?.logo ?? ""} isSquare data-sentry-element="Avatar" data-sentry-source-file="OrganizationMenuItem.tsx" />
      </div>
      <div className="flex h-12 w-full flex-col items-start justify-center pl-4">
        {withTitle && <div className="text-sm text-textMuted dark:text-textMutedDark">
            {t("title")}
          </div>}
        <div className={`font-semibold" flex flex-col items-start text-left text-sm ${darkText ? "text-textDark" : "text-text"} `}>
          {organization?.name}
        </div>
      </div>
    </div>;
};
export default OrganizationMenuItem;