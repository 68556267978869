import React from "react";
import { ModalOverlay, ModalOverlayProps, Modal as RACModal } from "react-aria-components";
const getOverlayStyles = ({
  isEntering,
  isExiting
}: {
  isEntering?: boolean;
  isExiting?: boolean;
}) => {
  const base = "fixed top-0 left-0 w-full h-[--visual-viewport-height] isolate z-20 bg-black/[15%] flex items-center md:items-center justify-center md:p-4 text-center backdrop-blur-lg";
  const entering = isEntering ? "animate-in fade-in duration-200 ease-out" : "";
  const exiting = isExiting ? "animate-out fade-out duration-200 ease-in" : "";
  return [base, entering, exiting].filter(Boolean).join(" ");
};
const getModalStyles = ({
  isEntering,
  isExiting
}: {
  isEntering?: boolean;
  isExiting?: boolean;
}) => {
  const base = "w-full md:max-w-[560px] max-h-[95vh] md:max-h-[70vh] rounded-sm bg-white dark:bg-zinc-800/70 dark:backdrop-blur-2xl dark:backdrop-saturate-200 forced-colors:bg-[Canvas] text-left align-middle text-slate-700 dark:text-zinc-300 shadow-2xl bg-clip-padding border border-black/10 dark:border-white/10";
  const entering = isEntering ? "animate-in zoom-in-105 ease-out duration-200" : "";
  const exiting = isExiting ? "animate-out zoom-out-95 ease-in duration-200" : "";
  return [base, entering, exiting].filter(Boolean).join(" ");
};
export function Modal(props: ModalOverlayProps) {
  return <ModalOverlay {...props} className={getOverlayStyles(props)} data-sentry-element="ModalOverlay" data-sentry-component="Modal" data-sentry-source-file="Modal.tsx">
      <RACModal {...props} className={getModalStyles(props)} data-sentry-element="RACModal" data-sentry-source-file="Modal.tsx" />
    </ModalOverlay>;
}