"use client";

import React from "react";
import { Button } from "@/edges/ui/Button";
import OrganizationMenuItem from "./components/OrganizationMenuItem";
import { useTranslations } from "next-intl";
import { useSelectedOrganization } from "@/state/user/useSelectedOrganization";
export interface OrganizationSwitcherProps {
  onSwitchOrganization: () => void;
}
function OrganizationSwitcher({
  onSwitchOrganization
}: OrganizationSwitcherProps) {
  const t = useTranslations();
  const {
    selectedOrganization
  } = useSelectedOrganization();
  if (!selectedOrganization) {
    return null;
  }
  return <>
      <OrganizationMenuItem organization={selectedOrganization} withTitle data-sentry-element="OrganizationMenuItem" data-sentry-source-file="OrganizationSwitcher.tsx" />
      <div className="flex w-full justify-end">
        <Button variant="secondary" onPress={onSwitchOrganization} data-sentry-element="Button" data-sentry-source-file="OrganizationSwitcher.tsx">
          {t("organization.switch")}
        </Button>
      </div>
    </>;
}
export { OrganizationSwitcher };