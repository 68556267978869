"use client";

import { useState } from "react";
import Image from "next/image";
import { Icon } from "@/edges/ui/Icon";
import { classNames } from "@/utils";
export interface AvatarProps {
  imageUrl?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  onClick?: () => void;
  size?: "sm" | "md" | "lg";
  isSquare?: boolean;
}
const Avatar = ({
  imageUrl,
  firstName,
  lastName,
  fullName,
  onClick,
  size = "md",
  isSquare = false
}: AvatarProps) => {
  const [hasImageError, setHasImageError] = useState(false);
  const handleImageError = () => setHasImageError(true);
  const sizeClasses = {
    sm: "h-9 w-9 text-sm",
    md: "h-12 w-12",
    lg: "h-14 w-14 text-lg"
  };
  const baseClasses = classNames(`${isSquare ? "" : "rounded-full border-1"}`, sizeClasses[size], onClick && "cursor-pointer");
  const imageClasses = classNames(baseClasses, "border-text");
  const initialsClasses = classNames(baseClasses, "relative inline-flex items-center border-gray-400 justify-center overflow-hidden bg-gray-100");
  const placeholderClasses = classNames(baseClasses, "relative overflow-hidden border-gray-400 bg-gray-100 dark:bg-gray-600 flex flex-col items-center justify-center");

  // If imageUrl exists, display image Avatar.
  if (imageUrl?.trim() && !hasImageError) return <Image onClick={onClick} className={imageClasses} src={imageUrl} width={48} height={48} alt={fullName || "User avatar"} onError={handleImageError} />;

  // If first name and/or last name exists, display placeholder initials Avatar.
  if (firstName || lastName) {
    const firstInitial = firstName ? firstName[0] : "";
    const lastInitial = lastName ? lastName[0] : "";
    const initials = firstInitial?.toUpperCase() + lastInitial?.toUpperCase();
    return <div onClick={onClick} className={initialsClasses}>
        <span className="mt-0.5 font-semibold">{initials}</span>
      </div>;
  }

  // Else display icon placeholder Avatar as a fallback.
  return <div onClick={onClick} className={placeholderClasses} data-sentry-component="Avatar" data-sentry-source-file="Avatar.tsx">
      <Icon name="User" className={sizeClasses[size]} data-sentry-element="Icon" data-sentry-source-file="Avatar.tsx" />
    </div>;
};
export { Avatar };