import React from "react";
import { Tooltip as AriaTooltip, TooltipProps as AriaTooltipProps, OverlayArrow, composeRenderProps, TooltipTrigger } from "react-aria-components";
export interface TooltipProps extends Omit<AriaTooltipProps, "children"> {
  children: React.ReactNode;
  content: React.ReactNode;
}
const styles = ({
  isEntering,
  isExiting,
  className
}: {
  isEntering?: boolean;
  isExiting?: boolean;
  className?: string;
}) => {
  const baseClasses = "max-w-[200px] group bg-slate-700 dark:bg-slate-600 border border-slate-800 dark:border-white/10 shadow-[inset_0_1px_0_0_theme(colors.gray.600)] dark:shadow-none text-white text-sm rounded-lg drop-shadow-lg will-change-transform px-3 py-1";
  const enteringClasses = isEntering ? "animate-in fade-in placement-bottom:slide-in-from-top-0.5 placement-top:slide-in-from-bottom-0.5 placement-left:slide-in-from-right-0.5 placement-right:slide-in-from-left-0.5 ease-out duration-200" : "";
  const exitingClasses = isExiting ? "animate-out fade-out placement-bottom:slide-out-to-top-0.5 placement-top:slide-out-to-bottom-0.5 placement-left:slide-out-to-right-0.5 placement-right:slide-out-to-left-0.5 ease-in duration-150" : "";
  return [baseClasses, enteringClasses, exitingClasses, className].filter(Boolean).join(" ");
};
export function Tooltip({
  children,
  content,
  ...props
}: TooltipProps) {
  return <TooltipTrigger delay={200} data-sentry-element="TooltipTrigger" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      {children}
      <AriaTooltip {...props} offset={10} className={composeRenderProps(props.className, (className, renderProps) => styles({
      ...renderProps,
      className
    }))} data-sentry-element="AriaTooltip" data-sentry-source-file="Tooltip.tsx">
        <OverlayArrow data-sentry-element="OverlayArrow" data-sentry-source-file="Tooltip.tsx">
          <svg width={8} height={8} viewBox="0 0 8 8" className="fill-slate-700 stroke-gray-800 group-placement-left:-rotate-90 group-placement-right:rotate-90 group-placement-bottom:rotate-180 dark:fill-slate-600 dark:stroke-white/10 forced-colors:fill-[Canvas] forced-colors:stroke-[ButtonBorder]" data-sentry-element="svg" data-sentry-source-file="Tooltip.tsx">
            <path d="M0 0 L4 4 L8 0" data-sentry-element="path" data-sentry-source-file="Tooltip.tsx" />
          </svg>
        </OverlayArrow>
        {content}
      </AriaTooltip>
    </TooltipTrigger>;
}