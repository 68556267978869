import { createContext, useContext, useEffect, useState } from "react";

// Moved to direct const variable in order to remove dependence on config tied to dash-next directly in edges
// Eventually we will want to move this folder out of dash-next and into a shared location
const preventDarkMode = true;
const ColorModeContext = createContext("");
export const useColorMode = () => useContext(ColorModeContext);
interface ColorModeProviderProps {
  children: React.ReactNode;
}
export const ColorModeProvider: React.FC<ColorModeProviderProps> = ({
  children
}) => {
  const [colorMode, setColorMode] = useState("");
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // Function to handle the theme change
    const handleColorModeChange = (e: MediaQueryListEvent) => {
      setColorMode(e.matches ? "dark" : "light");
    };

    // Set initial theme
    handleColorModeChange(mediaQuery);

    // Listen for changes in the prefers-color-scheme media query
    mediaQuery.addEventListener("change", handleColorModeChange);

    // Clean up the event listener on unmount
    return () => mediaQuery.removeEventListener("change", handleColorModeChange);
  }, []);
  if (preventDarkMode) {
    return <ColorModeContext.Provider value="light">
        {children}
      </ColorModeContext.Provider>;
  }
  return colorMode ? <ColorModeContext.Provider value={colorMode}>
      {children}
    </ColorModeContext.Provider> : <div />;
};