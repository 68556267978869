import {
  useCreateWorkspaceMutation,
  LoggedUserDocument,
  Workspace,
} from "@/@codegen/supergraph";
import { toast } from "react-hot-toast";
import { useSelectedOrganization } from "@/state/user/useSelectedOrganization";
import { useSelectedWorkspace } from "@/state/user/useSelectedWorkspace";
import { useTranslations } from "next-intl";
import { useUserService } from "@/services/userService";

interface UseAddWorkspaceProps {
  onSuccess?(): void;
  onCanceled?(): void;
}

export const useAddWorkspace = ({
  onSuccess,
  onCanceled,
}: UseAddWorkspaceProps) => {
  const t = useTranslations();
  const { selectedOrganization } = useSelectedOrganization();
  const { setSelectedWorkspace } = useSelectedWorkspace();

  const { fetchLoggedUser } = useUserService();
  const [
    createWorkspace,
    { loading: loadingCreateWorkspace, error: errorCreateWorkspace },
  ] = useCreateWorkspaceMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LoggedUserDocument }],
  });

  const handleAddWorkspace = ({ name }: { name: string }) => {
    createWorkspace({
      variables: {
        input: {
          name,
          organizationId: selectedOrganization!.id,
        },
      },
    })
      .then(({ data }) => {
        if (!data?.createWorkspace) {
          throw new Error(t("workspace.error"));
        }
        fetchLoggedUser().then(() => {
          setSelectedWorkspace(data?.createWorkspace as Workspace);
        });
        onSuccess?.();
        toast.success(t("workspace.createdSuccessfully", { name }));
      })
      .catch((err) => {
        toast.error(t("workspace.errors.created"));
        console.log("Error creating workspace", err);
        onCanceled?.();
      });
  };
  return {
    handleAddWorkspace,
    loadingCreateWorkspace,
    errorCreateWorkspace,
  };
};
