import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { Workspace, Organization } from "@/@codegen/supergraph";

type WorkspaceStore = {
  workspacesMap: Record<string, Workspace | null>;
  setSelectedWorkspace: (newWorkspace: Workspace | null, selectedOrganization: Organization) => void;
};

export const useWorkspaceStore = create<WorkspaceStore>()(
  persist(
    (set, get) => ({
      workspacesMap: {},
      setSelectedWorkspace: (selectedWorkspace, selectedOrganization) => {
        const workspacesMap = { ...get().workspacesMap };

        if (typeof selectedWorkspace === 'string') {
          const workspace = selectedOrganization?.workspaces?.find(
            (w) => w.id === selectedWorkspace,
          )

          if (!workspace) {
            throw new Error(`Workspace with id ${selectedWorkspace} not found in selected organization`);
          }

          workspacesMap[selectedOrganization.id] = workspace;
        } else {
          workspacesMap[selectedOrganization.id] = selectedWorkspace;
        }

        set({ workspacesMap });
      },
    }), {
    name: "@texture/workspace-storage",
    storage: createJSONStorage(() => window?.localStorage),
  }),
);
