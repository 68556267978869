import React from "react";
const sizeVariants = {
  xs: "text-headingXs font-semibold",
  sm: "text-headingSm font-semibold",
  md: "text-headingMd font-semibold",
  lg: "text-headingLg font-semibold",
  xl: "text-headingXl font-semibold"
} as const;
export type HeadingSize = keyof typeof sizeVariants;
export interface HeadingProps {
  tag?: keyof JSX.IntrinsicElements;
  size?: HeadingSize;
  className?: string;
  children?: React.ReactNode;
}
function Heading({
  tag: Tag = "h1",
  size = "lg",
  className = "",
  children
}: HeadingProps) {
  const sizeClasses = sizeVariants[size];
  return <Tag className={`${sizeClasses} ${className}`} data-sentry-element="Tag" data-sentry-component="Heading" data-sentry-source-file="Heading.tsx">{children}</Tag>;
}
export { Heading };