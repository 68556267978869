"use client";

import { useTranslations } from "next-intl";
import { useOrganizationService } from "@/services/organizationService";
import { toast } from "react-hot-toast";
import { Organization } from "@/@codegen/supergraph";

export default function useOrganizationList() {
  const t = useTranslations();

  const organizationService = useOrganizationService();

  function onSwitchOrganizationSession(organization: Organization) {
    return organizationService
      .switchOrganizationSession(organization.id)
      .then(() => {
        toast.success(t("successSwitchOrg", { name: organization.name }));
      })
      .catch((error) => {
        console.log("Error switching organization: ", error);
        toast.error(t("Operation failed, please try again."));
      });
  }

  return { onSwitchOrganizationSession };
}
