"use client";

import { useLoggedUser } from "@/state/user/useLoggedUser";

import { Organization } from "@/@codegen/supergraph";

export function useSelectedOrganization() {
  const { loggedUser } = useLoggedUser();
  const selectedOrganization = loggedUser?.authenticatedOrganization;

  return {
    selectedOrganization,
  };
}

//TODO: only used in tracking, and definitely should not be done like this. REMOVE
const onSelectedOrganizationChangeCallbacks = new Map<
  string,
  (organization: Organization | null) => void
>();

export function useOnSelectedOrganizationChange(
  callback: (organization: Organization | null) => void,
  key: string,
) {
  onSelectedOrganizationChangeCallbacks.set(key, callback);
}
