import React from "react";
import { AppWindow, ArrowCircleUp, ArrowDown, ArrowLeft, ArrowLineLeft, ArrowRight, ArrowUp, ArrowsClockwise, BatteryCharging, BatteryEmpty, BatteryFull, BatteryHigh, BatteryLow, BatteryMedium, BatteryWarning, BookmarkSimple, BookOpen, BracketsCurly, Buildings, CalendarBlank, CaretDown, CaretLeft, CaretRight, CaretUp, CarSimple, ChargingStation, ChartBar, ChartLineUp, Check, CheckCircle, CheckSquare, Circle, CircleDashed, CircleNotch, ClockCountdown, Cloud, CloudFog, CloudLightning, CloudRain, CloudSnow, CloudSun, Code, Columns, Copy, Cursor, DotsSix, DotsThree, DownloadSimple, EnvelopeSimple, Eye, EyeClosed, EyeSlash, Export, FireSimple, Flag, GearSix, HandPointing, Info, Lightning, LightningSlash, List, Lock, MagnifyingGlass, MapPin, MapPinArea, PaperPlaneRight, PencilSimple, Plugs, PlugsConnected, Plus, Power, Question, Repeat, ShareNetwork, ShieldCheck, SignOut, SlackLogo, Sliders, Snowflake, SolarPanel, Square, SquaresFour, Sun, Terminal, ThermometerCold, ThermometerHot, ThermometerSimple, Trash, TreeEvergreen, User, UserCircle, UserPlus, Users, UsersFour, Warning, WarningCircle, WebhooksLogo, Wind, X } from "@phosphor-icons/react";
import TextureMenuLight from "./TextureMenuLight.svg";
import TextureMenuDark from "./TextureMenuDark.svg";
interface IconProps {
  name: string;
  size?: number | string;
  color?: string;
  weight?: string;
  className?: string;
}
export const iconMapping: {
  [key: string]: React.ComponentType<{
    size?: number;
    color?: string;
  }>;
} = {
  AppWindow,
  ArrowCircleUp,
  ArrowDown,
  ArrowLeft,
  ArrowLineLeft,
  ArrowRight,
  ArrowUp,
  ArrowsClockwise,
  BatteryCharging,
  BatteryEmpty,
  BatteryFull,
  BatteryHigh,
  BatteryLow,
  BatteryMedium,
  BatteryWarning,
  BookOpen,
  BookmarkSimple,
  BracketsCurly,
  Buildings,
  CalendarBlank,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  CarSimple,
  ChargingStation,
  ChartBar,
  ChartLineUp,
  Check,
  CheckCircle,
  CheckSquare,
  Circle,
  CircleDashed,
  CircleNotch,
  ClockCountdown,
  Cloud,
  CloudFog,
  CloudLightning,
  CloudRain,
  CloudSnow,
  CloudSun,
  Code,
  Columns,
  Copy,
  Cursor,
  DotsSix,
  DotsThree,
  DownloadSimple,
  EnvelopeSimple,
  Eye,
  EyeClosed,
  EyeSlash,
  Export,
  FireSimple,
  Flag,
  GearSix,
  HandPointing,
  Info,
  Lightning,
  LightningSlash,
  List,
  Lock,
  MagnifyingGlass,
  MapPin,
  MapPinArea,
  PaperPlaneRight,
  PencilSimple,
  Plugs,
  PlugsConnected,
  Plus,
  Power,
  Question,
  Repeat,
  ShareNetwork,
  ShieldCheck,
  SignOut,
  SlackLogo,
  Sliders,
  Snowflake,
  SolarPanel,
  Square,
  SquaresFour,
  Sun,
  Terminal,
  ThermometerCold,
  ThermometerHot,
  ThermometerSimple,
  Trash,
  TreeEvergreen,
  User,
  UserCircle,
  UserPlus,
  Users,
  UsersFour,
  Warning,
  WarningCircle,
  WebhooksLogo,
  Wind,
  X
};
const Icon: React.FC<IconProps> = ({
  name,
  size = 24,
  color,
  weight,
  className
}) => {
  if (name === "TextureMenuLight") {
    return <img style={{
      height: size
    }} height={size} src={TextureMenuLight.src} alt="Texture Menu" />;
  }
  if (name === "TextureMenuDark") {
    return <img style={{
      height: size
    }} height={size} src={TextureMenuDark.src} alt="Texture Menu" />;
  }
  const IconComponent = iconMapping[name];
  if (!IconComponent) {
    console.warn(`Icon with name "${name}" does not exist.`);
    return null;
  }
  return <IconComponent style={{
    height: size
  }} size={size} color={color} weight={weight} className={className} data-sentry-element="IconComponent" data-sentry-component="Icon" data-sentry-source-file="Icon.tsx" />;
};
export { Icon };